import dynamic from 'next/dynamic';

import type { NextPageWithLayout } from 'types/next-page';

import Layout from '@/components/Layout/MainLayout';
import MetaTag from '@/components/MetaTag';

const MainContent = dynamic(() => import('@/components/contents/MainContent'));

const IndexPage: NextPageWithLayout = () => <MainContent />;

IndexPage.getLayout = function getLayout(page) {
  return (
    <Layout>
      <MetaTag />
      {page}
    </Layout>
  );
};

export default IndexPage;
