import { useMemo } from 'react';
import styled from '@emotion/styled';
import { breakpoints, fontSize, palette, spacing } from '@playdapp/ui';

import useHide from 'hooks/useHide';
import { useAppSelector } from 'store/hooks';

import Tooltip from '@/components/Tooltip';
import Redirect from '@/components/Redirect';

type Item = {
  name: string;
  imgName: string;
  to: string;
};

type Menu = {
  name: string;
  to: string;
};

type MenuList = Menu[];

const FooterContainer = styled.footer<{ isHide: boolean }>`
  display: ${({ isHide }) => isHide && 'none'};
  width: 100%;
  background-color: ${palette.gray200};
  border-top: 1px solid ${palette.gray400};

  a {
    cursor: pointer;
  }

  a:hover {
    color: ${palette.primary600};
  }
`;

const Contents = styled.div`
  width: 100%;
  max-width: 1560px;
  height: 100%;
  margin: 0 auto;
  padding: ${spacing.xxxl} 0 0 0;

  ${breakpoints.down('xxl')} {
    padding: ${spacing.xxl} ${spacing.xxl} 0 ${spacing.xxl};
  }

  ${breakpoints.down('md')} {
    padding: ${spacing.xl} ${spacing.m};
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const LogoArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: fit-content;

  ${breakpoints.down('md')} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.75rem;
  }
`;

const FooterLogo = styled.div`
  width: ${({ theme }) => theme.rem(177.5)};
  height: ${({ theme }) => theme.rem(40)};

  ${breakpoints.down('lg')} {
    width: ${({ theme }) => theme.rem(141.89)};
    height: ${({ theme }) => theme.rem(32)};
  }
`;

const LogoPlayDapp = styled.div`
  width: ${({ theme }) => theme.rem(177.5)};
  height: ${({ theme }) => theme.rem(40)};
  background-image: -webkit-image-set(
    url('/assets/images/footer/logo.png') 1x,
    url('/assets/images/footer/logo.png') 2x
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  ${breakpoints.down('lg')} {
    width: ${({ theme }) => theme.rem(141.89)};
    height: ${({ theme }) => theme.rem(32)};
  }
`;

const Description = styled.p`
  color: ${palette.gray900};
  font-size: ${fontSize.p4};
  margin-left: 1rem;

  ${breakpoints.down('md')} {
    font-size: ${fontSize.p5};
    margin: 0.75rem 0 0;
  }
`;

const Logo = styled.span`
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
  }
`;

const SiteMapArea = styled.div`
  display: flex;
  justify-content: flex-start;
  height: fit-content;
  gap: 0 ${spacing.xxl};
  margin-bottom: ${spacing.l};

  ${breakpoints.down('lg')} {
    flex-wrap: wrap;
  }

  ${breakpoints.down('md')} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 ${spacing.xxl};
  }
`;

const ResourceBox = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: ${fontSize.p4};
    padding-top: ${spacing.xl};
    white-space: pre;

    a {
      font-size: inherit;
    }
  }

  ${breakpoints.down('lg')} {
    span {
      padding-top: ${spacing.m};
    }
  }

  ${breakpoints.down('md')} {
    span {
      font-size: ${fontSize.p5};
    }
  }
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 286px);
  padding: ${spacing.l} 0 ${spacing.xxl} 0;
  border-top: 1px solid rgba(31, 31, 46, 0.1);
  z-index: 1;

  ${breakpoints.down('lg')} {
    padding: ${spacing.xxl} 0;
  }

  ${breakpoints.down('md')} {
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    padding: ${spacing.l} 0 0;
  }
`;

const CopyRightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Copyright = styled.small`
  font-weight: 400;
  font-size: ${({ theme }) => theme.rem(14)};
  color: rgba(31, 31, 46, 0.5);

  ${breakpoints.down('md')} {
    font-size: 10px;
  }
`;

const SnsIconBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${breakpoints.down('md')} {
    padding-bottom: ${spacing.m};
  }
`;

const SnsIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ theme }) => theme.rem(224)};
`;

const snsList: Item[] = [
  {
    name: 'facebook',
    imgName: 'facebook',
    to: 'https://www.facebook.com/playdapp.io',
  },
  {
    name: 'twitter',
    imgName: 'twitter',
    to: 'https://twitter.com/playdapp_io',
  },
  {
    name: 'telegram',
    imgName: 'telegram',
    to: 'https://t.me/cryptodozer_io',
  },
  {
    name: 'medium',
    imgName: 'medium',
    to: 'https://medium.com/playdappgames',
  },
  {
    name: 'discord',
    imgName: 'discord',
    to: 'https://discord.com/invite/playdapp-537515978561683466',
  },
  {
    name: 'CoinMarketCap',
    imgName: 'coin-market-cap',
    to: 'https://coinmarketcap.com/community/profile/PlayDapp_IO',
  },
];

const Footer = () => {
  const { isHideLayout, isAppInQuery } = useHide();
  const { account } = useAppSelector(({ wallet }) => wallet);

  const menuList: MenuList = useMemo(() => {
    const result = [
      {
        name: 'FAQ',
        to: '/faq/marketplace',
      },
      {
        name: 'Customer Support',
        to: 'https://playdapp.atlassian.net/servicedesk/customer/portals',
      },
      {
        name: 'My Page',
        to: '/my-page/items?my-items',
      },
      {
        name: 'Storefront',
        to: '/storefront',
      },
      {
        name: 'Term of service',
        to: '/tos',
      },
      {
        name: 'Privacy policy',
        to: '/policy',
      },
    ];

    return account ? result : result.filter((item) => item.name !== 'My Page');
  }, [account]);

  return (
    <FooterContainer isHide={isHideLayout}>
      <Contents>
        <ContentWrapper>
          <LogoArea>
            <FooterLogo>
              <Redirect to="/">
                <LogoPlayDapp />
              </Redirect>
            </FooterLogo>
            <Description>The Premier Marketplace for NFTs</Description>
          </LogoArea>
          <SiteMapArea>
            {menuList.map((menu) => (
              <ResourceBox key={`ResourceBox-${menu.name}`}>
                <span key={`${menu}`}>
                  <Redirect to={`${menu.to}${isAppInQuery ? '?app' : ''}`}>
                    {menu.name}
                  </Redirect>
                </span>
              </ResourceBox>
            ))}
          </SiteMapArea>
        </ContentWrapper>
        <BottomSection>
          <CopyRightWrapper>
            <Copyright>© Playdapp Limited All rights reserved.</Copyright>
          </CopyRightWrapper>
          <SnsIconBox>
            <SnsIcon>
              {snsList.map((item) => (
                <Redirect key={`${item}-${item.name}`} to={item.to}>
                  <Tooltip
                    hasArrow
                    label={item.name}
                    bgColor="#2F3037"
                    textColor="#ffffff"
                  >
                    <Logo>
                      <img
                        src={`/assets/images/icons/${item.imgName}.svg`}
                        alt={`icon-${item.name}`}
                      />
                    </Logo>
                  </Tooltip>
                </Redirect>
              ))}
            </SnsIcon>
          </SnsIconBox>
        </BottomSection>
      </Contents>
    </FooterContainer>
  );
};

export default Footer;
