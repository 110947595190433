import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';

type Props = {
  children: React.ReactNode;
  label: string;
  bgColor: string;
  textColor: string;
  hasArrow: boolean;
};

const TooltipBox = styled(ChakraTooltip)<{
  textColor: string;
  bgColor: string;
}>`
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 0.5rem;
`;

const Tooltip = ({ children, label, textColor, bgColor, hasArrow }: Props) => (
  <TooltipBox
    shouldWrapChildren
    label={label}
    hasArrow={hasArrow}
    textColor={textColor}
    bgColor={bgColor}
  >
    {children}
  </TooltipBox>
);

export default Tooltip;
