import Head from 'next/head';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  keywords?: string;
};

const MetaTag = ({
  title = 'PlayDapp : The Premier Marketplace for NFTs',
  description = 'The Premier Marketplace for NFTs',
  image = 'https://images.playdapp.com/marketplace/meta/og-image.png',
  keywords = 'NFT, Marketplace',
}: Props) => (
  <Head>
    <meta name="title" content={title} />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="author" content="PlayDapp" />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://market.playdapp.com" />
    <meta property="og:title" content={title} />
    <meta property="og:image" content={image} />
    <meta property="og:description" content={description} />

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://market.playdapp.com" />
    <meta name="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={image} />
    <title>{title}</title>
  </Head>
);
export default MetaTag;
