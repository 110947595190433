import { useCallback, useEffect, useRef } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchCollections } from 'store/collections';
import { fetchAssets } from 'store/assets';
import {
  setIsSwitchBundle,
  setSelectedMyItemsCollection,
  setSelectedMySalesCollection,
  setSelectedMySalesOrderFilter,
} from 'store/my-account';
import {
  setIsBundleActive,
  setOrOperations,
  setPrevUrl,
  setRangeFilters,
  setSelectedSaleTypeFilter,
} from 'store/filters';
import useUpdateWallet from 'hooks/useUpdateWallet';
import useOpenControl from 'hooks/useOpenControl';

import NetworkAlert from '../Modal/NetworkAlert';

const LayoutContainer: React.FC = ({ children }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { isSupported } = useUpdateWallet();
  const popStateUrl = useRef('');
  const currentUrl = useRef('/');

  const { prevUrl } = useAppSelector(({ filters }) => filters, shallowEqual);
  const { isModalOpen } = useAppSelector(({ connectModal }) => connectModal);

  const [isNetworkAlertOpen, handleNetworkAlertOpen] = useOpenControl();

  const handleResetFilter = useCallback(
    (url: string) => {
      if (!popStateUrl.current.includes(window.history.state.as)) {
        if (
          url.includes('/my-page/items') &&
          !currentUrl.current.includes('/my-page/items')
        ) {
          dispatch(setSelectedMyItemsCollection(null));
          popStateUrl.current = '';
        } else if (
          url.includes('/my-page/sales') &&
          !currentUrl.current.includes('/my-page/sales')
        ) {
          dispatch(setSelectedMySalesCollection(null));
          dispatch(
            setSelectedMySalesOrderFilter({ sale: false, offer: false }),
          );
          dispatch(setIsSwitchBundle(false));
          popStateUrl.current = '';
        } else if (
          url.includes('/nfts') &&
          !currentUrl.current.includes('/nfts') &&
          prevUrl !== 'merge'
        ) {
          dispatch(setRangeFilters([]));
          dispatch(setSelectedSaleTypeFilter(''));
          dispatch(setIsBundleActive(false));
          dispatch(setOrOperations([]));
          dispatch(setPrevUrl('nfts'));

          popStateUrl.current = '';
        }
      }
      currentUrl.current = url;
    },
    [dispatch, prevUrl],
  );

  useEffect(() => {
    router.beforePopState(({ as }) => {
      popStateUrl.current = as;
      return true;
    });

    currentUrl.current = router.asPath;
    router.events.on('routeChangeStart', handleResetFilter);

    return () => {
      router.events.off('routeChangeStart', handleResetFilter);
      router.beforePopState(() => true);
    };
  }, [router, handleResetFilter]);

  useEffect(() => {
    dispatch(fetchCollections());
    dispatch(fetchAssets());
  }, [dispatch]);

  useEffect(() => {
    handleNetworkAlertOpen(!isSupported);
  }, [isSupported, handleNetworkAlertOpen]);

  return (
    <>
      <Head>
        <title>PlayDapp : The Premier Marketplace for NFTs</title>
      </Head>
      {children}
      <NetworkAlert
        isOpen={!isModalOpen && isNetworkAlertOpen}
        handleOpen={handleNetworkAlertOpen}
      />
    </>
  );
};

export default LayoutContainer;
